<template>
  <v-app>
    <AppBarPublic />

    <v-main class="mb-10">
      <slot />
    </v-main>

    <Footer v-if="false" />
  </v-app>
</template>

<script>
import { version } from '@/package.json'

export default {
  components: {
    AppBarPublic: () => import('@components/structure/app-bar-public.vue'),
    Footer: () => import('@components/structure/footer.vue'),
  },

  data: () => ({
    version,
  }),

  created() {
    this.$vuetify.theme.dark = false

    // Prevent the install app from showing
    window.addEventListener('beforeinstallprompt', this.preventDefault)
  },

  beforeDestroy() {
    window.removeEventListener('beforeinstallprompt', this.preventDefault)
  },
  methods: {
    preventDefault(event) {
      event.preventDefault()
    },
  },
}
</script>

<style>
#app {
  background-color: var(--v-background-base);
}
</style>
